import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Shisha from "./Shisha";
import Admin from "./Admin";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Shisha />} />

        <Route path="/admin" element={<Admin />} />
      </Routes>
      <Analytics />
      <SpeedInsights />
    </Router>
  );
}

export default App;
