import React, { useState, useMemo, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import {
  Coffee,
  Beer,
  Wine,
  Droplet,
  Zap,
  Utensils,
  Search,
  Wind,
} from "lucide-react";

// Map of icon names to components
const iconComponents = {
  Coffee,
  Beer,
  Wine,
  Droplet,
  Zap,
  Utensils,
  Wind,
};

const MenuComponent = () => {
  const [menuData, setMenuData] = useState({
    sections: [],
    itemsBySection: {},
  });
  const [activeSection, setActiveSection] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSearchVisible, setIsSearchVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMenuData = async () => {
      try {
        const response = await fetch("/api/getItems");
        if (!response.ok) throw new Error("Failed to fetch menu data");

        const data = await response.json();

        // Transform the data into the format we need
        const itemsBySection = {};
        data.sections.forEach((section) => {
          itemsBySection[section.name] = section.items.filter(
            (item) => item !== null
          );
        });

        setMenuData({
          sections: data.sections,
          itemsBySection,
        });

        // Set initial active section
        if (data.sections.length > 0 && !activeSection) {
          setActiveSection(data.sections[0].name);
        }

        setIsLoading(false);
      } catch (err) {
        console.error("Error fetching menu:", err);
        setError("Could not load menu data");
        setIsLoading(false);
      }
    };

    fetchMenuData();
  }, []);

  const filteredItems = useMemo(() => {
    if (!activeSection || !menuData.itemsBySection[activeSection]) return [];

    return menuData.itemsBySection[activeSection].filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [activeSection, searchTerm, menuData.itemsBySection]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="text-purple-300">Loading menu...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="text-red-400">{error}</div>
      </div>
    );
  }

  return (
    <div className="max-h-[42.5rem] min-h-[42.5rem] max-md:max-h-[35rem] max-md:min-h-[35rem] w-full text-white p-4 flex flex-col">
      <div className="mb-6 flex justify-end max-md:hidden">
        <motion.button
          onClick={() => setIsSearchVisible(!isSearchVisible)}
          className="p-2 rounded-full"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <Search size={15} />
        </motion.button>
        <AnimatePresence>
          {isSearchVisible && (
            <motion.input
              initial={{ width: 0, opacity: 0 }}
              animate={{ width: "auto", opacity: 1 }}
              exit={{ width: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
              type="text"
              placeholder="Caută în meniu..."
              className="ml-2 p-2 border rounded"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          )}
        </AnimatePresence>
      </div>

      <div className="grid grid-cols-4 sm:grid-cols-7 gap-2 mb-4">
        {menuData.sections.map((section) => {
          const IconComponent = iconComponents[section.icon_name];
          return (
            <motion.button
              key={section.name}
              className={`flex flex-col h-14 items-center justify-center p-2 rounded-lg ${
                activeSection === section.name
                  ? "bg-purple-600 bg-opacity-50"
                  : "bg-purple-800 bg-opacity-30 hover:bg-opacity-40"
              } backdrop-blur-sm transition-all duration-300`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setActiveSection(section.name)}
            >
              <div className="text-purple-200">
                {IconComponent && <IconComponent size={24} />}
              </div>
              <span className="mt-1 text-xs text-center">{section.name}</span>
            </motion.button>
          );
        })}
      </div>

      <AnimatePresence mode="wait">
        {activeSection && (
          <motion.div
            key={activeSection}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="flex-grow bg-purple-900 bg-opacity-30 rounded-lg p-4 backdrop-blur-sm overflow-y-auto"
          >
            <h3 className="text-2xl font-semibold mb-4 text-purple-300 max-md:text-md max-md:mb-2">
              {activeSection}
            </h3>
            <ul className="space-y-2">
              {filteredItems.map((item, index) => (
                <motion.li
                  key={item.id}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: index * 0.1 }}
                  className="flex justify-between items-start p-2 hover:bg-purple-700 hover:bg-opacity-30 rounded-lg transition-colors duration-100 max-md:text-sm max-md:p-1"
                >
                  <div className="flex flex-col">
                    <span>{item.name}</span>
                    {item.quantity && (
                      <span className="text-gray-400 text-xs">
                        {item.quantity}
                      </span>
                    )}
                  </div>
                  <span className="font-semibold whitespace-nowrap">
                    {item.price} RON
                    {item.perHour && <span className="text-xs ml-1">/ora</span>}
                  </span>
                </motion.li>
              ))}
            </ul>
          </motion.div>
        )}
      </AnimatePresence>

      <div className="mt-4 text-center text-purple-300 text-sm">
        <p>Toate prețurile includ TVA</p>
      </div>
      <div className="mt-12"></div>
    </div>
  );
};

export default MenuComponent;
