import React, { useEffect, useRef, useState, useCallback } from "react";
import "./parallax.css";

// Custom hook for screen size
const useScreenSize = () => {
  const [screenSize, setScreenSize] = useState({
    isMobile: window.innerWidth <= 768,
    isTablet: window.innerWidth <= 1024 && window.innerWidth > 768,
    isDesktop: window.innerWidth > 1024,
  });

  useEffect(() => {
    const handleResize = () => {
      setScreenSize({
        isMobile: window.innerWidth <= 768,
        isTablet: window.innerWidth <= 1024 && window.innerWidth > 768,
        isDesktop: window.innerWidth > 1024,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return screenSize;
};

interface ParallaxGridProps {
  images: string[];
}

interface ImageProps {
  size: "small" | "medium" | "large";
  offsets: {
    mobile: { x: number; y: number };
    tablet: { x: number; y: number };
    desktop: { x: number; y: number };
  };
  depth: number;
}

const ParallaxGrid: React.FC<ParallaxGridProps> = ({ images }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [allImagesLoaded, setAllImagesLoaded] = useState(false);
  const { isMobile, isTablet, isDesktop } = useScreenSize();

  // Converting your original imageStyles to the new responsive format
  const imageStyles: ImageProps[] = [
    {
      size: "large",
      offsets: {
        mobile: { x: -10, y: 20 },
        tablet: { x: 0, y: 30 },
        desktop: { x: 0, y: 40 },
      },
      depth: 0.6,
    },
    {
      size: "large",
      offsets: {
        mobile: { x: -10, y: 0 },
        tablet: { x: 15, y: 0 },
        desktop: { x: 20, y: 0 },
      },
      depth: 0.4,
    },
    {
      size: "medium",
      offsets: {
        mobile: { x: 0, y: 30 },
        tablet: { x: -15, y: -130 },
        desktop: { x: -20, y: -240 },
      },
      depth: 0.5,
    },
    {
      size: "large",
      offsets: {
        mobile: { x: 30, y: 40 },
        tablet: { x: 90, y: 35 },
        desktop: { x: 120, y: -20 },
      },
      depth: 0.3,
    },
    {
      size: "medium",
      offsets: {
        mobile: { x: -20, y: -150 },
        tablet: { x: 100, y: -250 },
        desktop: { x: 20, y: -400 },
      },
      depth: 0.3,
    },
    {
      size: "medium",
      offsets: {
        mobile: { x: -23, y: -100 },
        tablet: { x: 10, y: -175 },
        desktop: { x: 15, y: -300 },
      },
      depth: 0.4,
    },
    {
      size: "small",
      offsets: {
        mobile: { x: -37, y: 150 },
        tablet: { x: -10, y: 200 },
        desktop: { x: -15, y: 200 },
      },
      depth: 0.1,
    },
    {
      size: "medium",
      offsets: {
        mobile: { x: -230, y: -150 },
        tablet: { x: -30, y: -225 },
        desktop: { x: -400, y: -300 },
      },
      depth: 0.25,
    },
    {
      size: "small",
      offsets: {
        mobile: { x: -35, y: 50 },
        tablet: { x: -7, y: 50 },
        desktop: { x: -10, y: 0 },
      },
      depth: 0.45,
    },
    {
      size: "large",
      offsets: {
        mobile: { x: -15, y: -100 },
        tablet: { x: 22, y: -175 },
        desktop: { x: 30, y: -300 },
      },
      depth: 0.4,
    },
    {
      size: "medium",
      offsets: {
        mobile: { x: -40, y: -400 },
        tablet: { x: -15, y: -650 },
        desktop: { x: -20, y: -1000 },
      },
      depth: 0.4,
    },
    {
      size: "small",
      offsets: {
        mobile: { x: 70, y: -250 },
        tablet: { x: 225, y: -450 },
        desktop: { x: 300, y: -600 },
      },
      depth: 0.2,
    },
    {
      size: "small",
      offsets: {
        mobile: { x: -230, y: -350 },
        tablet: { x: -412, y: -550 },
        desktop: { x: -550, y: -800 },
      },
      depth: 0.48,
    },
  ];

  const getResponsiveOffset = useCallback(
    (imageStyle: ImageProps) => {
      if (isMobile) return imageStyle.offsets.mobile;
      if (isTablet) return imageStyle.offsets.tablet;
      return imageStyle.offsets.desktop;
    },
    [isMobile, isTablet]
  );

  const updateImagePositions = useCallback(
    (scrollY: number) => {
      const container = containerRef.current;
      if (!container) return;

      const wrappers = container.querySelectorAll<HTMLDivElement>(
        ".parallax-image-wrapper"
      );

      wrappers.forEach((wrapper, index) => {
        const imageStyle = imageStyles[index];
        if (!imageStyle) return;

        const { depth } = imageStyle;
        const { x: xOffset, y: yOffset } = getResponsiveOffset(imageStyle);
        const translateY = scrollY * depth + yOffset;
        const translateX = xOffset;
        wrapper.style.transform = `translate3d(${translateX}px, ${translateY}px, 0)`;
      });
    },
    [imageStyles, getResponsiveOffset]
  );

  const getResponsiveSize = useCallback(
    (size: "small" | "medium" | "large"): string => {
      if (isMobile) {
        return size === "small" ? "20%" : size === "medium" ? "30%" : "40%";
      }
      if (isTablet) {
        return size === "small" ? "25%" : size === "medium" ? "32%" : "37%";
      }
      return size === "small" ? "20%" : size === "medium" ? "30%" : "40%";
    },
    [isMobile, isTablet]
  );

  const getWrapperStyle = useCallback(
    (index: number): React.CSSProperties => {
      const { size } = imageStyles[index];
      return {
        width: getResponsiveSize(size),
        display: "inline-block",
        willChange: "transform",
        marginBottom: isMobile ? "10px" : "20px",
      };
    },
    [imageStyles, getResponsiveSize, isMobile]
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    let ticking = false;
    let lastScrollY = container.scrollTop * 1.05;

    const handleScroll = () => {
      lastScrollY = container.scrollTop * 1.05;
      if (!ticking) {
        window.requestAnimationFrame(() => {
          updateImagePositions(lastScrollY);
          ticking = false;
        });
        ticking = true;
      }
    };

    updateImagePositions(lastScrollY);
    container.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, [updateImagePositions]);

  useEffect(() => {
    const preloadImages = async () => {
      const imagePromises = images.map(
        (src) =>
          new Promise<void>((resolve, reject) => {
            const img = new Image();
            img.src = src;
            img.onload = () => resolve();
            img.onerror = reject;
          })
      );

      try {
        await Promise.all(imagePromises);
        setAllImagesLoaded(true);
      } catch (error) {
        console.error("Error loading images:", error);
      }
    };

    preloadImages();
  }, [images]);

  return (
    <div
      ref={containerRef}
      className="parallax-container rounded"
      style={{
        perspective: "1px",
        overflowY: "scroll",
        height: isMobile ? "45vh" : isTablet ? "70vh" : "92vh",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        scrollBehavior: "smooth",
      }}
    >
      <div className="absolute text-5xl top-[40rem] max-md:top-[10rem] left-[20%] z-20 text-gradient opacity-80">
        Relaxing experience
      </div>
      <div className="absolute text-5xl top-[100rem] max-md:top-[45rem] max-sm:left-[20%] md:right-[20%] z-20 text-gradient opacity-80">
        Large variety of flavors
      </div>
      <div className="absolute text-5xl top-[160rem] max-md:top-[80rem] left-[20%] z-20 text-gradient opacity-80">
        Fun activities
      </div>
      {allImagesLoaded && (
        <div
          className="flex flex-wrap justify-center gap-3 p-5 max-lg:p-5 rounded"
          style={{ alignContent: "flex-start", paddingBottom: "5rem" }}
        >
          {images.map((imageUrl, index) => (
            <div
              key={index}
              className="parallax-image-wrapper"
              style={getWrapperStyle(index)}
            >
              <img
                src={imageUrl}
                alt={`Image ${index + 1}`}
                className="transition-opacity duration-100 opacity-100 max-w-full h-auto rounded-lg border-4 border-zinc-950 drop-shadow-xl max-md:border-2"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ParallaxGrid;
