import React, { useRef, useEffect, useMemo } from "react";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import hookahModel from "../assets/3d/hookah65.glb";

interface Shisha3dProps {
  width: number;
  height: number;
}

const Shisha3d: React.FC<Shisha3dProps> = ({ width, height }) => {
  const mountRef = useRef<HTMLDivElement>(null);
  const sceneRef = useRef<THREE.Scene | null>(null);
  const cameraRef = useRef<THREE.PerspectiveCamera | null>(null);
  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const modelRef = useRef<THREE.Object3D | null>(null);
  const frameIdRef = useRef<number | null>(null);

  const isMobile = useMemo(() => window.innerWidth < 1024, []);

  useEffect(() => {
    if (!mountRef.current || width === 0 || height === 0) return;

    // Scene setup
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(25, width / height, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true,
      powerPreference: "high-performance",
    });

    renderer.setSize(width, height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    mountRef.current.innerHTML = "";
    mountRef.current.appendChild(renderer.domElement);

    // Lighting
    const ambientLight = new THREE.AmbientLight(0x9a48d0, 0.3);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0x00a5e0, 8);
    directionalLight.position.set(2, 2, 2);
    scene.add(directionalLight);

    const secondDirectionalLight = new THREE.DirectionalLight(0x9a48d0, 12);
    secondDirectionalLight.position.set(-2, -2, -2);
    scene.add(secondDirectionalLight);

    sceneRef.current = scene;
    cameraRef.current = camera;
    rendererRef.current = renderer;

    // Load 3D model
    const loader = new GLTFLoader();
    loader.load(
      hookahModel,
      (gltf) => {
        const model = gltf.scene;
        modelRef.current = model;

        model.traverse((node) => {
          if ((node as THREE.Mesh).isMesh) {
            const mesh = node as THREE.Mesh;
            if ((mesh.material as THREE.Material).isMaterial) {
              const material = mesh.material as THREE.MeshStandardMaterial;
              material.needsUpdate = true;
            }
          }
        });

        const box = new THREE.Box3().setFromObject(model);
        const center = box.getCenter(new THREE.Vector3());
        model.position.sub(center);

        const size = box.getSize(new THREE.Vector3());
        const maxDim = Math.max(size.x, size.y, size.z);

        const scale = isMobile
          ? (Math.min(width, height) / maxDim) * 0.5
          : (Math.min(width, height) / maxDim) * 0.2;

        model.scale.set(scale, scale, scale);

        scene.add(model);

        const distance = maxDim / Math.tan((camera.fov * Math.PI) / 360);
        camera.position.z = distance * 15;
        camera.position.y = isMobile ? 40 : 40;

        camera.aspect = width / height;
        camera.updateProjectionMatrix();

        // Start animation loop
        animate();
      },
      undefined,
      (error) => {
        console.error("An error occurred while loading the model:", error);
      }
    );

    // Mouse movement rotation (only for desktop)
    const onMouseMove = (event: MouseEvent) => {
      if (isMobile || !modelRef.current) return;

      const rotationX = (event.clientY / height) * Math.PI * 0.05;
      const rotationY = (event.clientX / width) * Math.PI * 0.3;

      modelRef.current.rotation.x = rotationX;
      modelRef.current.rotation.y = rotationY;
    };

    // Add event listener only for desktop
    if (!isMobile) {
      window.addEventListener("mousemove", onMouseMove);
    }

    // Animation loop
    const animate = () => {
      if (
        sceneRef.current &&
        cameraRef.current &&
        rendererRef.current &&
        modelRef.current
      ) {
        if (isMobile) {
          modelRef.current.rotation.y += 0.002;
        }
        rendererRef.current.render(sceneRef.current, cameraRef.current);
      }
      frameIdRef.current = requestAnimationFrame(animate);
    };

    // Cleanup
    return () => {
      if (!isMobile) {
        window.removeEventListener("mousemove", onMouseMove);
      }
      if (frameIdRef.current) {
        cancelAnimationFrame(frameIdRef.current);
      }
      if (rendererRef.current && rendererRef.current.domElement) {
        rendererRef.current.domElement.remove();
      }
      if (rendererRef.current) {
        rendererRef.current.dispose();
      }
    };
  }, [width, height, isMobile]);

  return (
    <div
      ref={mountRef}
      style={{ width: `${width}px`, height: `${height}px` }}
      className=""
    />
  );
};

export default Shisha3d;
