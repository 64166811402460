import React, { useState, useCallback } from "react";
import { useSpring, animated } from "react-spring";
import { ParallaxScrollDemo } from "../components/ui/parallax-wrapper";
import TransitionComponent from "../components/TransitionComponent";
import MenuComponent from "../components/MenuComponent";
import BookComponent from "../components/BookComponent";
import ContactComponent from "../components/ContactComponent";
import "./index.css";

type MainSectionProps = {
  onShrink: (shrink: boolean) => void;
};

const MainSection: React.FC<MainSectionProps> = React.memo(({ onShrink }) => {
  const [activeSection, setActiveSection] = useState("main");
  const buttonSpring = useSpring({
    from: { transform: "scale(1)" },
    config: { tension: 200, friction: 12 },
  });

  const ButtonStyle =
    "px-6 py-2 text-white font-semibold rounded-full transition-all duration-300 bg-opacity-80 backdrop-filter backdrop-blur-sm hover:bg-opacity-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-purple-800 focus:ring-white text-xl max-md:text-sm";

  const handleButtonClick = useCallback(
    (section) => {
      setActiveSection(section);
      onShrink(section === "menu" || section === "book" || section === "info");
    },
    [onShrink]
  );

  return (
    <div className="flex flex-col w-auto h-screen overflow-hidden">
      <div className="h-[8%] flex justify-center items-center relative">
        <div className="absolute inset-0 bg-opacity-10 z-0"></div>
        <div className="flex space-x-4 z-10 max-md:space-x-1">
          {["main", "menu", "book", "info"].map((section) => (
            <animated.button
              key={section}
              style={buttonSpring}
              onClick={() => handleButtonClick(section)}
              className={`${ButtonStyle} ${
                activeSection === section
                  ? "bg-purple-500 hover:bg-purple-600"
                  : section === "menu"
                  ? "bg-blue-500 hover:bg-blue-600"
                  : section === "book"
                  ? "bg-green-500 hover:bg-green-600"
                  : section === "info"
                  ? " "
                  : ""
              } bg-gray-500/30`}
            >
              {section.toUpperCase()}
            </animated.button>
          ))}
        </div>
      </div>
      <div className="h-[92%] relative">
        <TransitionComponent activeSection={activeSection}>
          {(section) => {
            switch (section) {
              case "menu":
                return <MenuComponent />;
              case "book":
                return (
                  <div className="2xl:mt-0  md:mt-0 ">
                    {" "}
                    <BookComponent />
                  </div>
                );
              case "info":
                return <ContactComponent />;
              default:
                return <ParallaxScrollDemo />;
            }
          }}
        </TransitionComponent>
      </div>
    </div>
  );
});

export default MainSection;
