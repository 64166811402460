import React from "react";
import { motion } from "framer-motion";

const LoadingScreen: React.FC = () => {
  const text = "Shisha Brothers";

  const letterVariants = {
    initial: { y: 50, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  const fillVariants = {
    initial: { width: "0%" },
    animate: {
      width: "100%",
      transition: {
        duration: 1.5,
        ease: "easeInOut",
      },
    },
  };

  return (
    <motion.div
      initial={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="fixed inset-0 z-50 flex items-center justify-center bg-dark_purple"
    >
      <div className="relative">
        {text.split("").map((char, index) => (
          <motion.span
            key={`${char}-${index}`}
            variants={letterVariants}
            initial="initial"
            animate="animate"
            transition={{ delay: index * 0.1 }}
            className="inline-block text-6xl max-md:text-4xl font-bold text-white relative tracking-widest font-montserrat"
            style={{ WebkitTextStroke: "1px white", color: "transparent" }}
          >
            {char}
            <motion.span
              variants={fillVariants}
              initial="initial"
              animate="animate"
              transition={{ delay: 1 + index * 0.1 }}
              className="absolute inset-0 overflow-hidden"
              style={{ color: "white" }}
            >
              {char}
            </motion.span>
          </motion.span>
        ))}
      </div>
    </motion.div>
  );
};

export default LoadingScreen;
