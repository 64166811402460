import React from "react";
import { motion } from "framer-motion";
import { CheckCircle } from "lucide-react";

const ReservationSuccess = ({ tableId, date, time, onClose }) => {
  // Format the date to be more readable
  const formattedDate = new Date(date).toLocaleDateString("en-US", {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  return (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      className="fixed inset-0 flex items-center justify-center bg-opacity-60 backdrop-blur-sm z-50"
    >
      <div className="bg-dark_purple/30 backdrop-blur-lg border border-gray-700 p-6 rounded-xl shadow-2xl max-w-md mx-auto relative">
        <div className="absolute inset-0 w-full h-full bg-gradient-to-t from-gray-900 via-gray-800/50 to-gray-700/20 rounded-xl opacity-40 pointer-events-none"></div>

        <div className="flex flex-col items-center text-center space-y-4">
          <CheckCircle className="w-16 h-16 text-green-500" />

          <h2 className="text-2xl font-bold text-white">
            Reservation Confirmed!
          </h2>

          <div className="text-gray-300 space-y-2">
            <p>Your table has been successfully reserved</p>
            <p className="font-semibold">Table {tableId}</p>
            <p>{formattedDate}</p>
            <p>at {time}</p>
          </div>

          <button
            onClick={onClose}
            className="mt-6 px-6 py-2  text-white rounded-lg  transition-colors duration-300 focus:outline-none focus:ring-2 bg-gray-600/40"
          >
            Close
          </button>
        </div>
      </div>
    </motion.div>
  );
};

export default ReservationSuccess;
