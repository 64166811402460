import React from "react";
import GlassCard from "../components/GlassCard";
import Shisha3dWrapper from "../components/Shisha3dWrapper";
import { useSpring, animated } from "react-spring";

const Presentation = ({ isVisible }) => {
  const shishaVisibility = useSpring({
    opacity: isVisible ? 1 : 0,
    config: { tension: 170, friction: 26 },
  });

  return (
    <GlassCard
      blur="2xl"
      opacity="90"
      borderOpacity="30"
      shadowIntensity="xl"
      className="w-full h-full relative overflow-hidden"
    >
      <div className="w-full h-full flex justify-center items-center">
        <animated.div
          style={shishaVisibility}
          className="w-full h-full max-w-3xl max-h-[80vh] max-sm:scale-[1.6] max-sm:mb-12 "
        >
          <Shisha3dWrapper />
        </animated.div>
      </div>
      <div className="absolute inset-0 flex flex-col justify-center items-center pointer-events-none z-10">
        <div className="w-auto h-auto max-w-full max-h-[50vh] object-contain mix-blend-difference font-impact text-shadow-white text-4xl sm:text-5xl md:text-6xl xl:text-7xl">
          SHISHA Brothers
        </div>
        <div className="w-auto h-auto max-w-full max-h-[50vh] object-contain mix-blend-difference font-impact text-shadow-white text-sm sm:text-base md:text-lg lg:text-xl">
          NACHOS & MORE
        </div>
      </div>
    </GlassCard>
  );
};

export default Presentation;
