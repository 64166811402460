"use client";
import React from "react";

import image2 from "../../assets/Album/2.webp";
import image3 from "../../assets/Album/3.webp";
import image5 from "../../assets/Album/5.webp";
import image6 from "../../assets/Album/6.webp";
import image7 from "../../assets/Album/7.webp";
import image9 from "../../assets/Album/9.webp";
import image10 from "../../assets/Album/10.webp";
import image11 from "../../assets/Album/11.webp";
import image12 from "../../assets/Album/12.webp";
import image15 from "../../assets/Album/15.webp";

import image17 from "../../assets/Album/17.webp";
import image19 from "../../assets/Album/19.webp";

import image22 from "../../assets/Album/22.webp";
import image24 from "../../assets/Album/24.webp";
import ParallaxGrid from "./parallax-scroll";

export function ParallaxScrollDemo() {
  return <ParallaxGrid images={images} />;
}

const images = [
  image24,
  image22,
  image6,
  image5,
  image7,
  image9,
  image15,
  image10,
  image17,
  image19,
  //image11,
  image12,
  image2,
  image3,

  // image15,
];
