import React, { useState, useEffect, useRef } from "react";
import Shisha3d from "./shisha3d";

const Shisha3dWrapper: React.FC = () => {
  const [dimensions, setDimensions] = useState({ width: 300, height: 400 });
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateDimensions = () => {
      if (wrapperRef.current) {
        const { clientWidth, clientHeight } = wrapperRef.current;
        const aspectRatio = 1 / 2; // Adjust this to match your model's aspect ratio

        let newWidth, newHeight;

        if (clientWidth / clientHeight > aspectRatio) {
          newHeight = clientHeight;
          newWidth = newHeight * aspectRatio;
        } else {
          newWidth = clientWidth;
          newHeight = newWidth / aspectRatio;
        }

        setDimensions({ width: newWidth, height: newHeight });
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div
      ref={wrapperRef}
      className="w-full h-full flex justify-center items-center"
    >
      <div
        style={{
          width: `${dimensions.width}px`,
          height: `${dimensions.height}px`,
        }}
      >
        <Shisha3d width={dimensions.width} height={dimensions.height} />
      </div>
    </div>
  );
};

export default Shisha3dWrapper;
