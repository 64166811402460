"use client";
import { cn } from "../../lib/utils";
import { useEffect, useRef, useState, useCallback, useMemo } from "react";
import React from "react";

const GradientCircle = React.memo(({ className }) => (
  <div className={className}></div>
));

GradientCircle.displayName = "GradientCircle";

export const BackgroundGradientAnimation = ({
  gradientBackgroundStart = "rgb(77, 2, 77)",
  gradientBackgroundEnd = "rgb(1, 45, 54)",
  firstColor = "1, 28, 39",
  secondColor = "0, 165, 224",
  thirdColor = "0, 165, 224",
  fourthColor = "154, 72, 208",
  fifthColor = "154, 72, 208",
  pointerColor = "154, 72, 208",
  size = "60%",
  blendingValue = "hard-light",
  children,
  className,
  interactive = false,
  containerClassName,
}) => {
  const interactiveRef = useRef(null);
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });
  const [targetPos, setTargetPos] = useState({ x: 0, y: 0 });
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    const styles = {
      "--gradient-background-start": gradientBackgroundStart,
      "--gradient-background-end": gradientBackgroundEnd,
      "--first-color": firstColor,
      "--second-color": secondColor,
      "--third-color": thirdColor,
      "--fourth-color": fourthColor,
      "--fifth-color": fifthColor,
      "--pointer-color": pointerColor,
      "--size": size,
      "--blending-value": blendingValue,
    };
    Object.entries(styles).forEach(([key, value]) => {
      document.body.style.setProperty(key, value);
    });

    setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));

    return () => {
      Object.keys(styles).forEach((key) => {
        document.body.style.removeProperty(key);
      });
    };
  }, []);

  useEffect(() => {
    if (!interactive || !interactiveRef.current) return;

    let animationFrameId;
    const move = () => {
      setCursorPos((prev) => ({
        x: prev.x + (targetPos.x - prev.x) / 20,
        y: prev.y + (targetPos.y - prev.y) / 20,
      }));
      animationFrameId = requestAnimationFrame(move);
    };
    move();

    return () => cancelAnimationFrame(animationFrameId);
  }, [interactive, targetPos]);

  const handleMouseMove = useCallback((event) => {
    if (interactiveRef.current) {
      const rect = interactiveRef.current.getBoundingClientRect();
      setTargetPos({
        x: event.clientX - rect.left,
        y: event.clientY - rect.top,
      });
    }
  }, []);

  const gradientCircles = useMemo(
    () => [
      {
        className: cn(
          `absolute [background:radial-gradient(circle_at_center,_var(--first-color)_0,_var(--first-color)_50%)_no-repeat]`,
          `[mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)]`,
          `[transform-origin:center_center]`,
          `animate-first`,
          `opacity-100`
        ),
      },
      {
        className: cn(
          `absolute [background:radial-gradient(circle_at_center,_rgba(var(--second-color),_0.8)_0,_rgba(var(--second-color),_0)_50%)_no-repeat]`,
          `[mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)]`,
          `[transform-origin:calc(50%-400px)]`,
          `animate-second`,
          `opacity-100`
        ),
      },
      {
        className: cn(
          `absolute [background:radial-gradient(circle_at_center,_rgba(var(--third-color),_0.8)_0,_rgba(var(--third-color),_0)_50%)_no-repeat]`,
          `[mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)]`,
          `[transform-origin:calc(50%+400px)]`,
          `animate-third`,
          `opacity-100`
        ),
      },
      {
        className: cn(
          `absolute [background:radial-gradient(circle_at_center,_rgba(var(--fourth-color),_0.8)_0,_rgba(var(--fourth-color),_0)_50%)_no-repeat]`,
          `[mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)]`,
          `[transform-origin:calc(50%-200px)]`,
          `animate-fourth`,
          `opacity-70`
        ),
      },
      {
        className: cn(
          `absolute [background:radial-gradient(circle_at_center,_rgba(var(--fifth-color),_0.8)_0,_rgba(var(--fifth-color),_0)_50%)_no-repeat]`,
          `[mix-blend-mode:var(--blending-value)] w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)]`,
          `[transform-origin:calc(50%-800px)_calc(50%+800px)]`,
          `animate-fifth`,
          `opacity-100`
        ),
      },
    ],
    []
  );

  return (
    <div
      className={cn(
        "h-screen w-screen absolute overflow-hidden top-0 left-0 bg-[linear-gradient(40deg,var(--gradient-background-start),var(--gradient-background-end))]",
        containerClassName
      )}
    >
      <svg className="hidden">
        <defs>
          <filter id="blurMe">
            <feGaussianBlur
              in="SourceGraphic"
              stdDeviation="10"
              result="blur"
            />
            <feColorMatrix
              in="blur"
              mode="matrix"
              values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8"
              result="goo"
            />
            <feBlend in="SourceGraphic" in2="goo" />
          </filter>
        </defs>
      </svg>
      <div className={cn("", className)}>{children}</div>
      <div
        className={cn(
          "gradients-container h-full w-full blur-lg",
          isSafari ? "blur-2xl" : "[filter:url(#blurMe)_blur(40px)]"
        )}
      >
        {gradientCircles.map((circle, index) => (
          <GradientCircle key={index} className={circle.className} />
        ))}
        {interactive && (
          <div
            ref={interactiveRef}
            onMouseMove={handleMouseMove}
            className={cn(
              `absolute [background:radial-gradient(circle_at_center,_rgba(var(--pointer-color),_0.8)_0,_rgba(var(--pointer-color),_0)_50%)_no-repeat]`,
              `[mix-blend-mode:var(--blending-value)] w-full h-full -top-1/2 -left-1/2`,
              `opacity-70`
            )}
            style={{
              transform: `translate(${Math.round(cursorPos.x)}px, ${Math.round(
                cursorPos.y
              )}px)`,
            }}
          />
        )}
      </div>
    </div>
  );
};
