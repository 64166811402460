import React, { useState, useEffect } from "react";
import AdminLogin from "./AdminLogin";
import { LogOut, Edit, Trash2 } from "lucide-react";
import RestaurantProgramManagement from "./RestaurantProgramManagement";
import MenuManagement from "./MenuManager";

const Admin = () => {
  const [activeTab, setActiveTab] = useState("reservations");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editingReservation, setEditingReservation] = useState(null);

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    return date.toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const fetchReservations = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch("/api/getReservations");
      if (!response.ok) {
        throw new Error("Failed to fetch reservations");
      }
      const data = await response.json();
      setReservations(data);
    } catch (err) {
      console.error("Error fetching reservations:", err);
      setError("Failed to load reservations. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteReservation = async (id) => {
    if (window.confirm("Are you sure you want to delete this reservation?")) {
      try {
        const response = await fetch("/api/getReservations", {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to delete reservation");
        }

        // Remove the deleted reservation from the list
        setReservations(
          reservations.filter((reservation) => reservation.id !== id)
        );
      } catch (err) {
        console.error("Error deleting reservation:", err);
        setError(err.message);
      }
    }
  };

  const handleUpdateReservation = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("/api/getReservations", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          id: editingReservation.id,
          tableid: editingReservation.tableid,
          date: editingReservation.date,
          time: editingReservation.time,
          name: editingReservation.name,
          phonenumber: editingReservation.phonenumber,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to update reservation");
      }

      // Refresh reservations list
      await fetchReservations();

      // Reset editing state
      setEditingReservation(null);
    } catch (err) {
      console.error("Error updating reservation:", err);
      setError(err.message);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchReservations();
      // Set up auto-refresh every 5 minutes
      const interval = setInterval(fetchReservations, 5 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [isLoggedIn]);

  const handleLoginSuccess = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  const EditReservationModal = () => {
    if (!editingReservation) return null;

    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
        <div className="bg-white rounded-lg shadow-xl p-6 w-96">
          <h2 className="text-xl font-semibold mb-4">Edit Reservation</h2>
          <form onSubmit={handleUpdateReservation}>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Table Number
              </label>
              <input
                type="number"
                value={editingReservation.tableid}
                onChange={(e) =>
                  setEditingReservation({
                    ...editingReservation,
                    tableid: e.target.value,
                  })
                }
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Date
              </label>
              <input
                type="date"
                value={editingReservation.date}
                onChange={(e) =>
                  setEditingReservation({
                    ...editingReservation,
                    date: e.target.value,
                  })
                }
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Time
              </label>
              <input
                type="time"
                value={editingReservation.time}
                onChange={(e) =>
                  setEditingReservation({
                    ...editingReservation,
                    time: e.target.value,
                  })
                }
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Name
              </label>
              <input
                type="text"
                value={editingReservation.name}
                onChange={(e) =>
                  setEditingReservation({
                    ...editingReservation,
                    name: e.target.value,
                  })
                }
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-gray-700 text-sm font-bold mb-2">
                Phone Number
              </label>
              <input
                type="tel"
                value={editingReservation.phonenumber}
                onChange={(e) =>
                  setEditingReservation({
                    ...editingReservation,
                    phonenumber: e.target.value,
                  })
                }
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                required
              />
            </div>
            <div className="flex justify-between">
              <button
                type="submit"
                className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Update Reservation
              </button>
              <button
                type="button"
                onClick={() => setEditingReservation(null)}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  };

  const ReservationDashboard = () => {
    return (
      <div className="min-h-screen ">
        {editingReservation && <EditReservationModal />}
        <div className="p-8">
          <h2 className="text-2xl font-bold mb-6">Current Reservations</h2>
          <div className="bg-white rounded-lg shadow">
            <div className="px-6 py-4 border-b border-gray-200">
              <div className="flex justify-between items-center">
                <button
                  onClick={fetchReservations}
                  className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                >
                  Refresh
                </button>
              </div>
            </div>
            <div className="p-6">
              {loading && (
                <div className="text-center py-4">
                  <p className="text-gray-500">Loading reservations...</p>
                </div>
              )}

              {error && (
                <div className="text-center py-4">
                  <p className="text-red-500">{error}</p>
                </div>
              )}

              {!loading && !error && (
                <div className="overflow-x-auto">
                  {reservations.length === 0 ? (
                    <p className="text-center py-4 text-gray-500">
                      No reservations found
                    </p>
                  ) : (
                    <table className="w-full text-sm text-left">
                      <thead className="text-xs uppercase bg-gray-50">
                        <tr>
                          <th className="px-6 py-3 border-b">Table</th>
                          <th className="px-6 py-3 border-b">Date</th>
                          <th className="px-6 py-3 border-b">Time</th>
                          <th className="px-6 py-3 border-b">Name</th>
                          <th className="px-6 py-3 border-b">Phone</th>
                          <th className="px-6 py-3 border-b">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {reservations.map((reservation) => (
                          <tr
                            key={reservation.id}
                            className="bg-white border-b hover:bg-gray-50"
                          >
                            <td className="px-6 py-4">
                              Table {reservation.tableid}
                            </td>
                            <td className="px-6 py-4">
                              {formatDate(reservation.date)}
                            </td>
                            <td className="px-6 py-4">{reservation.time}</td>
                            <td className="px-6 py-4">{reservation.name}</td>
                            <td className="px-6 py-4">
                              {reservation.phonenumber}
                            </td>
                            <td className="px-6 py-4 flex space-x-2">
                              <button
                                onClick={() =>
                                  setEditingReservation(reservation)
                                }
                                className="text-blue-600 hover:text-blue-800"
                                title="Edit Reservation"
                              >
                                <Edit className="h-5 w-5" />
                              </button>
                              <button
                                onClick={() =>
                                  handleDeleteReservation(reservation.id)
                                }
                                className="text-red-600 hover:text-red-800"
                                title="Delete Reservation"
                              >
                                <Trash2 className="h-5 w-5" />
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      {isLoggedIn ? (
        <>
          <nav className="flex gap-4 bg-gray-100 p-4 rounded-lg">
            <button
              onClick={() => setActiveTab("reservations")}
              className={`px-4 py-2 rounded-md text-gray-600 border border-gray-300 
      ${
        activeTab === "reservations"
          ? "bg-gray-500 text-white"
          : "bg-white hover:bg-gray-200"
      }`}
            >
              Reservations
            </button>
            <button
              onClick={() => setActiveTab("restaurant-program")}
              className={`px-4 py-2 rounded-md text-gray-600 border border-gray-300 
      ${
        activeTab === "restaurant-program"
          ? "bg-gray-500 text-white"
          : "bg-white hover:bg-gray-200"
      }`}
            >
              Restaurant Program
            </button>
            <button
              onClick={() => setActiveTab("restaurant-menu")}
              className={`px-4 py-2 rounded-md text-gray-600 border border-gray-300 
      ${
        activeTab === "restaurant-menu"
          ? "bg-gray-500 text-white"
          : "bg-white hover:bg-gray-200"
      }`}
            >
              Menu Manager
            </button>
            <button
              onClick={handleLogout}
              className="flex items-center justify-end px-4 text-red-600 hover:text-red-800"
            >
              <LogOut className="h-5 w-5 mr-2" />
              Logout
            </button>
          </nav>

          {activeTab === "reservations" && <ReservationDashboard />}
          {activeTab === "restaurant-program" && (
            <RestaurantProgramManagement />
          )}
          {activeTab === "restaurant-menu" && <MenuManagement />}
        </>
      ) : (
        <AdminLogin onLoginSuccess={handleLoginSuccess} />
      )}
    </div>
  );
};

export default Admin;
