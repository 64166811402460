import React, { useState, useEffect } from "react";
import { Plus, Edit, Trash2, ChevronDown, ChevronUp } from "lucide-react";
import { Alert } from "@windmill/react-ui";

const MenuManagement = () => {
  const [sections, setSections] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editingSection, setEditingSection] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const [expandedSections, setExpandedSections] = useState({});

  // Fetch menu sections and items
  const fetchMenuData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch("/api/menu");
      if (!response.ok) throw new Error("Failed to fetch menu data");
      const data = await response.json();
      setSections(data.sections);
    } catch (err) {
      setError("Failed to load menu data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMenuData();
  }, []);

  // Section management
  const handleAddSection = () => {
    setEditingSection({
      name: "",
      icon_name: "",
      display_order: sections.length + 1,
      items: [],
    });
  };

  const handleSaveSection = async (updatedSection) => {
    try {
      const method = updatedSection.id ? "PUT" : "POST";
      const body = {
        type: "section",
        ...(updatedSection.id && { id: updatedSection.id }),
        data: {
          name: updatedSection.name,
          icon_name: updatedSection.icon_name,
          display_order: updatedSection.display_order,
        },
      };

      const response = await fetch("/api/menu", {
        method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      if (!response.ok) throw new Error("Failed to save section");
      await fetchMenuData();
      setEditingSection(null);
    } catch (err) {
      setError("Failed to save section. Please try again.");
    }
  };

  const handleDeleteSection = async (sectionId) => {
    if (!window.confirm("Delete this section and all its items?")) return;
    try {
      const response = await fetch(`/api/menu?type=section&id=${sectionId}`, {
        method: "DELETE",
      });
      if (!response.ok) throw new Error("Failed to delete section");
      await fetchMenuData();
    } catch (err) {
      setError("Failed to delete section. Please try again.");
    }
  };

  // Item management
  const handleAddItem = (sectionId) => {
    setEditingItem({
      section_id: sectionId,
      name: "",
      price: 0,
      quantity: "",
      per_hour: false,
      display_order: sections.find((s) => s.id === sectionId).items.length + 1,
    });
  };

  const handleSaveItem = async (updatedItem) => {
    try {
      const method = updatedItem.id ? "PUT" : "POST";
      const body = {
        type: "item",
        ...(updatedItem.id && { id: updatedItem.id }),
        data: {
          section_id: updatedItem.section_id,
          name: updatedItem.name,
          price: updatedItem.price,
          quantity: updatedItem.quantity,
          per_hour: updatedItem.per_hour,
          display_order: updatedItem.display_order,
        },
      };

      const response = await fetch("/api/menu", {
        method,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body),
      });
      if (!response.ok) throw new Error("Failed to save item");
      await fetchMenuData();
      setEditingItem(null);
    } catch (err) {
      setError("Failed to save item. Please try again.");
    }
  };

  const handleDeleteItem = async (itemId) => {
    if (!window.confirm("Delete this menu item?")) return;
    try {
      const response = await fetch(`/api/menu?type=item&id=${itemId}`, {
        method: "DELETE",
      });
      if (!response.ok) throw new Error("Failed to delete item");
      await fetchMenuData();
    } catch (err) {
      setError("Failed to delete item. Please try again.");
    }
  };

  // Section Form
  const SectionForm = ({ section, onSave, onCancel }) => {
    const [localSection, setLocalSection] = useState(section);

    const handleChange = (key, value) => {
      setLocalSection({ ...localSection, [key]: value });
    };

    return (
      <div className="bg-white p-4 rounded-lg shadow mb-4">
        <h3 className="text-lg font-semibold mb-4">
          {section.id ? "Edit Section" : "New Section"}
        </h3>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              value={localSection.name}
              onChange={(e) => handleChange("name", e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Icon Name
            </label>
            <input
              type="text"
              value={localSection.icon_name}
              onChange={(e) => handleChange("icon_name", e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button
              onClick={onCancel}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={() => onSave(localSection)}
              className="px-4 py-2 text-sm font-medium text-white bg-gray-600 rounded-md hover:bg-blue-700"
            >
              Save Section
            </button>
          </div>
        </div>
      </div>
    );
  };

  // Item Form
  const ItemForm = ({ item, onSave, onCancel }) => {
    const [localItem, setLocalItem] = useState(item);

    const handleChange = (key, value) => {
      setLocalItem({ ...localItem, [key]: value });
    };

    return (
      <div className="bg-white p-4 rounded-lg shadow mb-4">
        <h3 className="text-lg font-semibold mb-4">
          {item.id ? "Edit Item" : "New Item"}
        </h3>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              value={localItem.name}
              onChange={(e) => handleChange("name", e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Price
            </label>
            <input
              type="number"
              value={localItem.price}
              onChange={(e) =>
                handleChange("price", parseFloat(e.target.value))
              }
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Quantity
            </label>
            <input
              type="text"
              value={localItem.quantity}
              onChange={(e) => handleChange("quantity", e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            />
          </div>
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={localItem.per_hour}
              onChange={(e) => handleChange("per_hour", e.target.checked)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label className="ml-2 block text-sm text-gray-700">Per Hour</label>
          </div>
          <div className="flex justify-end space-x-2">
            <button
              onClick={onCancel}
              className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              onClick={() => onSave(localItem)}
              className="px-4 py-2 text-sm font-medium text-white bg-gray-600 rounded-md hover:bg-blue-700"
            >
              Save Item
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Menu Management</h1>
      {error && <Alert type="danger">{error}</Alert>}
      {loading ? (
        <div>Loading...</div>
      ) : (
        <>
          {editingSection && (
            <SectionForm
              section={editingSection}
              onSave={handleSaveSection}
              onCancel={() => setEditingSection(null)}
            />
          )}
          {editingItem && (
            <ItemForm
              item={editingItem}
              onSave={handleSaveItem}
              onCancel={() => setEditingItem(null)}
            />
          )}
          <button
            onClick={handleAddSection}
            className="px-4 py-2 mb-4 text-sm font-medium text-white bg-gray-600 rounded-md hover:bg-blue-700"
          >
            <Plus className="mr-2" /> Add Section
          </button>
          {sections.map((section) => (
            <div key={section.id} className="border p-4 rounded-lg mb-4 shadow">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-semibold">{section.name}</h2>
                <div className="space-x-2">
                  <button onClick={() => setEditingSection(section)}>
                    <Edit className="text-blue-500" />
                  </button>
                  <button onClick={() => handleDeleteSection(section.id)}>
                    <Trash2 className="text-red-500" />
                  </button>
                  <button
                    onClick={() =>
                      setExpandedSections((prev) => ({
                        ...prev,
                        [section.id]: !prev[section.id],
                      }))
                    }
                  >
                    {expandedSections[section.id] ? (
                      <ChevronUp />
                    ) : (
                      <ChevronDown />
                    )}
                  </button>
                </div>
              </div>
              {expandedSections[section.id] && (
                <>
                  <button
                    onClick={() => handleAddItem(section.id)}
                    className="mt-4 px-4 py-2 text-sm font-medium text-white bg-gray-600 rounded-md hover:bg-blue-600"
                  >
                    <Plus className="mr-2" /> Add Item
                  </button>
                  {section.items.map((item) => (
                    <div
                      key={item.id}
                      className="mt-4 bg-gray-100 p-4 rounded-lg"
                    >
                      <div className="flex items-center justify-between">
                        <h3 className="font-semibold">{item.name}</h3>
                        <div className="space-x-2">
                          <button onClick={() => setEditingItem(item)}>
                            <Edit className="text-blue-500" />
                          </button>
                          <button onClick={() => handleDeleteItem(item.id)}>
                            <Trash2 className="text-red-500" />
                          </button>
                        </div>
                      </div>
                      <p className="text-gray-600">Price: {item.price} RON</p>
                      <p className="text-gray-600">Quantity: {item.quantity}</p>
                      <p className="text-gray-600">
                        Per Hour: {item.per_hour ? "Yes" : "No"}
                      </p>
                    </div>
                  ))}
                </>
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default MenuManagement;
