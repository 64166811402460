import React from "react";
import { useTransition, animated } from "react-spring";

const TransitionComponent = ({ children, activeSection }) => {
  // Optimize transition with smoother parameters and less jitter
  const transitions = useTransition(activeSection, {
    from: { opacity: 0, transform: "translate3d(100%,0,0)" },
    enter: { opacity: 1, transform: "translate3d(0%,0,0)" },
    leave: { opacity: 0, transform: "translate3d(-50%,0,0)" },
    config: { tension: 150, friction: 25, mass: 1 }, // Fine-tuned for smoother performance
  });

  return transitions((style, item) => (
    <animated.div
      style={{ ...style, willChange: "transform, opacity" }} // Use `will-change` to optimize rendering
      className="absolute w-full h-full"
    >
      {children(item)}
    </animated.div>
  ));
};

export default React.memo(TransitionComponent); // Memoize the component to prevent unnecessary re-renders
