import React, { useState, useEffect } from "react";
import image from "../assets/book4.webp";

import { motion } from "framer-motion";
import ReservationSuccess from "./ReservationSuccess";

const tables = [
  { id: 1, xPercent: 67.3, yPercent: 90.3 },
  { id: 2, xPercent: 82, yPercent: 80.5 },
  { id: 3, xPercent: 80, yPercent: 70 },
  { id: 4, xPercent: 68, yPercent: 70 },
  { id: 5, xPercent: 55.5, yPercent: 70 },
  { id: 6, xPercent: 48, yPercent: 54 },
  { id: 7, xPercent: 48, yPercent: 38 },
  { id: 8, xPercent: 48, yPercent: 33.8 },
  { id: 9, xPercent: 23, yPercent: 33.5 },
  { id: 10, xPercent: 23, yPercent: 55.5 },
  { id: 11, xPercent: 17.5, yPercent: 23.5 },
  { id: 12, xPercent: 17.5, yPercent: 5.5 },
];

const TableButton = ({ tableId, xPercent, yPercent, onTableSelect }) => (
  <button
    onClick={() => onTableSelect(tableId)}
    style={{
      position: "absolute",
      left: `${xPercent}%`,
      top: `${yPercent}%`,
      transform: "translate(-50%, -50%)",
      width: "10%",
      height: "3%",
      minWidth: "20px",
      minHeight: "20px",
      borderRadius: "50%",
      cursor: "pointer",
    }}
    aria-label={`Book table ${tableId}`}
  />
);

interface ReservationFormProps {
  tableId: number;
  onClose: () => void;
}

const ReservationForm: React.FC<ReservationFormProps> = ({
  tableId,
  onClose,
}) => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [error, setError] = useState("");
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [unavailableTimes, setUnavailableTimes] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [consents, setConsents] = useState({
    dataProcessing: false,
  });
  const availableTimes = [
    "12:00 PM",
    "2:00 PM",
    "4:00 PM",
    "6:00 PM",
    "8:00 PM",
    "10:00 PM",
    "12:00 AM",
    "2:00 AM",
  ];

  useEffect(() => {
    if (selectedDate) {
      fetch(`/api/unavailable-times?tableId=${tableId}&date=${selectedDate}`)
        .then((response) => response.json())
        .then((data) => {
          setUnavailableTimes(data.unavailableTimes);
        })
        .catch((error) =>
          console.error("Failed to fetch unavailable times", error)
        );
    }
  }, [tableId, selectedDate]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    if (!consents.dataProcessing) {
      setError("Please provide consent for data processing before submitting.");
      return;
    }

    try {
      const response = await fetch("/api/reservations", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tableId,
          name,
          phoneNumber,
          date: selectedDate,
          time: selectedTime,
        }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log("Reservation created:", result);
        setShowSuccess(true); // Show success notification instead of closing immediately
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Failed to create reservation");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("An error occurred while creating the reservation");
    }
  };

  const filteredAvailableTimes = availableTimes.filter(
    (time) => !unavailableTimes.includes(time)
  );
  const handleConsentChange =
    (consentType: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setConsents((prev) => ({ ...prev, [consentType]: e.target.checked }));
    };
  const handleSuccessClose = () => {
    setShowSuccess(false);
    onClose();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-opacity-60 backdrop-blur-sm overflow-y-auto py-6 ">
      {showSuccess ? (
        <ReservationSuccess
          tableId={tableId}
          date={selectedDate}
          time={selectedTime}
          onClose={handleSuccessClose}
        />
      ) : (
        <div className="relative bg-dark_purple/30 backdrop-blur-lg border border-gray-700 p-4 sm:p-6 md:p-8 rounded-xl shadow-2xl w-full max-w-md mx-auto my-auto  max-md:max-w-64 max-md:-mt-0 ">
          <div className="absolute inset-0 w-full h-full bg-gradient-to-t from-gray-900 via-gray-800/50 to-gray-700/20 rounded-xl opacity-40 pointer-events-none "></div>

          <h2 className="text-2xl max-md:text-lg font-bold text-white mb-4 max-md:mb-2">
            Reserve Table {tableId}
          </h2>

          <form onSubmit={handleSubmit} className="space-y-4 max-md:space-y-3">
            <div>
              <label
                htmlFor="name"
                className="block mb-1 text-sm text-gray-300"
              >
                Name:
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                className="w-full px-3 py-2 max-md:py-1 rounded-lg bg-gray-700 text-gray-100 border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 max-md:text-sm"
              />
            </div>

            <div>
              <label
                htmlFor="phoneNumber"
                className="block mb-1 text-sm text-gray-300"
              >
                Phone Number:
              </label>
              <input
                type="tel"
                id="phoneNumber"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                required
                className="w-full px-3 py-2 max-md:py-1 rounded-lg bg-gray-700 text-gray-100 border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 max-md:text-sm"
              />
            </div>

            <div>
              <label
                htmlFor="date"
                className="block mb-1 text-sm text-gray-300"
              >
                Select Date:
              </label>
              <input
                type="date"
                id="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                required
                className="w-full px-3 py-2 max-md:py-1 rounded-lg bg-gray-700 text-gray-100 border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 max-md:text-sm"
              />
            </div>

            <div>
              <label
                htmlFor="time"
                className="block mb-1 text-sm text-gray-300"
              >
                Select Time:
              </label>
              <select
                id="time"
                value={selectedTime}
                onChange={(e) => setSelectedTime(e.target.value)}
                required
                className="w-full px-3 py-2 max-md:py-1 rounded-lg bg-gray-700 text-gray-100 border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300 max-md:text-sm"
              >
                <option value="" disabled>
                  Select a time
                </option>
                {filteredAvailableTimes.map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label className="flex items-center text-sm max-md:text-xs text-gray-300">
                <input
                  type="checkbox"
                  checked={consents.dataProcessing}
                  onChange={handleConsentChange("dataProcessing")}
                  className="mr-2"
                  required
                />
                I consent to the processing of my personal data for the purpose
                of table reservation.
              </label>
            </div>

            <button
              type="button"
              onClick={() => setShowPrivacyPolicy(true)}
              className="text-sm text-gray-300 underline max-md:text-xs"
            >
              View Privacy Policy
            </button>

            {error && <p className="text-sm text-red-500">{error}</p>}

            <div className="flex justify-end space-x-4 mt-6">
              <button
                type="button"
                onClick={onClose}
                className="px-4 py-2 text-sm rounded-lg bg-gray-600 text-gray-200 hover:bg-gray-500 focus:outline-none focus:ring-2 focus:ring-gray-400 transition-all duration-300"
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-4 py-2 text-sm rounded-lg bg-blue-600 text-white hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-400 transition-all duration-300 bg-gray-600/40"
              >
                Reserve
              </button>
            </div>
          </form>
        </div>
      )}
      {showPrivacyPolicy && (
        <PrivacyPolicy onClose={() => setShowPrivacyPolicy(false)} />
      )}
    </div>
  );
};

const PrivacyPolicy = ({ onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-opacity-60 backdrop-blur-sm ">
      <div className="bg-white p-8 rounded-xl shadow-2xl max-w-2xl max-h-[60vh] overflow-y-auto text-xs max-md:max-h-[50vh] max-md:max-w-80 max-md:-mt-48 ">
        <h2 className="text-2xl font-bold mb-4">Privacy Policy</h2>

        <p>
          At <strong>Shisha Brothers</strong>, we are committed to protecting
          the privacy and security of your personal information. This Privacy
          Policy outlines how we collect, use, and protect your data when you
          make a reservation with us.
        </p>

        <p>
          By making a reservation, you agree to the terms outlined in this
          policy. When you make a reservation at Shisha Brothers, we collect the
          following personal data:
        </p>

        <ul>
          <li>
            <strong>Phone Number:</strong> This is the only personal data we
            collect, and it is used solely to identify and manage your
            reservation.
          </li>
        </ul>

        <p>The phone number you provide is used for the following purposes:</p>

        <ul>
          <li>
            <strong>Reservation Identification:</strong> To confirm and manage
            your booking at Shisha Brothers.
          </li>
          <li>
            <strong>Communication:</strong> If we need to contact you regarding
            your reservation (e.g., changes, confirmations, or updates).
          </li>
        </ul>

        <p>
          We do not retain your personal information beyond the time necessary
          to manage your reservation. Specifically:
        </p>

        <ul>
          <li>
            <strong>Data Deletion:</strong> Your phone number will be deleted
            from our records immediately after the reservation has been
            completed or canceled.
          </li>
        </ul>

        <p>
          As a customer, you have the following rights concerning your personal
          data:
        </p>

        <ul>
          <li>
            <strong>Access:</strong> You have the right to request information
            about any data we hold about you.
          </li>
          <li>
            <strong>Rectification:</strong> If your personal data is inaccurate
            or incomplete, you have the right to request its correction.
          </li>
          <li>
            <strong>Erasure:</strong> You have the right to request that we
            delete any personal information we hold about you after your
            reservation.
          </li>
        </ul>

        <p>
          We take appropriate technical and organizational measures to ensure
          that your data is handled securely and protected against unauthorized
          access, disclosure, or misuse. These measures include:
        </p>

        <ul>
          <li>
            Secure storage of your personal information during the reservation
            process.
          </li>
          <li>
            Immediate deletion of your data once your reservation has been
            completed or canceled.
          </li>
        </ul>

        <p>
          We do not share, sell, or disclose your personal information to any
          third parties. Your phone number is used exclusively by Shisha
          Brothers for reservation purposes.
        </p>

        <p>
          If you have any questions or concerns about this Privacy Policy or
          your personal data, please feel free to contact us at:
        </p>

        <ul>
          <li>
            <strong>Email:</strong> noradsociety@gmail.com
          </li>
          <li>
            <strong>Phone:</strong> (0775) 113 172
          </li>
          <li>
            <strong>Address:</strong> Republicii 42, Târnăveni
          </li>
        </ul>

        <p>
          We reserve the right to update this Privacy Policy as needed to
          reflect changes in our practices or legal obligations. Any updates
          will be posted on our website.
        </p>

        <p>
          <em>Effective Date: 12.10.2024</em>
        </p>

        <button
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-gray-400 text-white rounded hover:bg-blue-600 z-10"
        >
          Close
        </button>
      </div>
    </div>
  );
};

const BookingSystem = () => {
  const [aspectRatio, setAspectRatio] = useState(1);
  const [selectedTable, setSelectedTable] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = image;
    img.onload = () => {
      setAspectRatio(img.height / img.width);
      setImageLoaded(true);
    };
  }, []);

  const handleTableSelect = (tableId) => {
    setSelectedTable(tableId);
  };

  return (
    <div className="w-full h-screen overflow-y-auto">
      <div className="booking-system w-full flex flex-col lg:flex-row items-center justify-center px-4 mb-52 lg:mt-20">
        <div
          className="w-full max-w-md lg:w-1/2 lg:max-w-xl relative max-md:w-[85%] max-md:mt-7"
          style={{
            maxHeight: "calc(90vh -20px)",
          }}
        >
          <div
            style={{
              paddingBottom: `${aspectRatio * 100}%`,
              maxHeight: "100%",
            }}
            className="relative w-full h-full"
          >
            <div className="absolute inset-0">
              <img
                src={image}
                alt="Restaurant floor plan"
                className="w-full h-full object-contain"
                onLoad={() => setImageLoaded(true)}
              />
              {imageLoaded && (
                <div className="absolute inset-0 grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
                  {tables.map((table) => (
                    <TableButton
                      key={table.id}
                      tableId={table.id}
                      xPercent={table.xPercent}
                      yPercent={table.yPercent}
                      onTableSelect={handleTableSelect}
                      selected={selectedTable === table.id}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {selectedTable && (
        <ReservationForm
          tableId={selectedTable}
          onClose={() => setSelectedTable(null)}
        />
      )}
    </div>
  );
};

export default BookingSystem;
