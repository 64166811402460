import React, { useState, useEffect } from "react";
import { useSpring, animated } from "react-spring";
import MainSection from "./sections/MainSection";
import Presentation from "./sections/Presentation";
import LoadingScreen from "./components/LoadingScreen";
import { BackgroundGradientAnimation } from "./components/ui/background-gradient-animation";

// Custom hook for window width
const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    const debounceResize = debounce(handleResize, 200); // Debounce for 200ms
    window.addEventListener("resize", debounceResize);

    return () => window.removeEventListener("resize", debounceResize);
  }, []);

  return windowWidth;
};

// Debounce helper function
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

const Shiha = () => {
  const [isShrunk, setIsShrunk] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const windowWidth = useWindowWidth();
  const isLargeScreen = windowWidth >= 1024;

  const isVisible = isLargeScreen || !isShrunk;

  const presentationHeightSpring = useSpring({
    height: isLargeScreen ? "100%" : isShrunk ? "15%" : "40%",
    config: { tension: 170, friction: 26 },
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1070); // Loading animation duration

    // Prevent scrolling during loading
    document.body.style.overflow = "hidden";
    document.documentElement.style.overflow = "hidden";

    return () => {
      clearTimeout(timer);
      document.body.style.overflow = "";
      document.documentElement.style.overflow = "";
    };
  }, []);

  return (
    <>
      {isLoading && <LoadingScreen />}
      <div
        className={`fixed inset-0 w-full h-full ${
          isLoading ? "invisible" : "visible"
        }`}
      >
        <BackgroundGradientAnimation />
        <div className="relative z-10 flex flex-col lg:flex-row w-full h-full">
          <animated.section
            style={presentationHeightSpring}
            className="w-full lg:w-2/5"
          >
            <Presentation isVisible={isVisible} />
          </animated.section>
          <section className="w-full lg:w-3/5 h-1/2 lg:h-full">
            <MainSection
              onShrink={(shrink) => !isLargeScreen && setIsShrunk(shrink)}
            />
          </section>
        </div>
      </div>
    </>
  );
};

export default Shiha;
