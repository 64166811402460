import React from "react";

const GlassCard = ({
  children,
  className = "",
  blur = "md",
  opacity = "20",
  borderOpacity = "20",
  shadowIntensity = "md",
}) => {
  const blurClasses = {
    sm: "backdrop-blur-sm",
    md: "backdrop-blur-md",
    lg: "backdrop-blur-lg",
    xl: "backdrop-blur-xl",
    "2xl": "backdrop-blur-2xl",
    "3xl": "backdrop-blur-3xl",
  };

  const shadowClasses = {
    sm: "shadow-sm",
    md: "shadow-md",
    lg: "shadow-lg",
    xl: "shadow-xl",
    "2xl": "shadow-2xl",
  };

  return (
    <div
      className={`
      ${blurClasses[blur]} 
      bg-white/0 
      bg-opacity-${opacity} 
      max-md:border-b border-white/10  md:border-r
      border-opacity-${borderOpacity}
      ${shadowClasses[shadowIntensity]}
      p-6
      transition-all
      duration-300
      hover:bg-opacity-${Number(opacity) + 10}
      hover:border-opacity-${Number(borderOpacity) + 10}
      ${className}
    `}
    >
      {children}
    </div>
  );
};

export default GlassCard;
