import React, { useState, useEffect } from "react";
import { Edit, Save, X } from "lucide-react";

const DAYS = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

interface ReservationTime {
  day_of_week: number;
  start_time: string;
  end_time: string;
  is_available: boolean;
}

const RestaurantProgramManagement: React.FC = () => {
  const [reservationTimes, setReservationTimes] = useState<ReservationTime[]>(
    []
  );
  const [editingDay, setEditingDay] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchReservationTimes = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch("/api/restaurantProgram");
      if (!response.ok) {
        throw new Error("Failed to fetch restaurant program");
      }
      const data = await response.json();
      setReservationTimes(data);
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "An unknown error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateReservationTimes = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch("/api/restaurantProgram", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reservationTimes),
      });

      if (!response.ok) {
        throw new Error("Failed to update restaurant program");
      }

      setEditingDay(null);
      await fetchReservationTimes();
    } catch (err) {
      setError(
        err instanceof Error ? err.message : "An unknown error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const toggleDayAvailability = (dayIndex: number) => {
    const updatedTimes = [...reservationTimes];
    const index = updatedTimes.findIndex((rt) => rt.day_of_week === dayIndex);

    if (index !== -1) {
      updatedTimes[index] = {
        ...updatedTimes[index],
        is_available: !updatedTimes[index].is_available,
      };
      setReservationTimes(updatedTimes);
    }
  };

  const updateDayTime = (
    dayIndex: number,
    field: "start_time" | "end_time",
    value: string
  ) => {
    const updatedTimes = [...reservationTimes];
    const index = updatedTimes.findIndex((rt) => rt.day_of_week === dayIndex);

    if (index !== -1) {
      updatedTimes[index] = {
        ...updatedTimes[index],
        [field]: value,
      };
      setReservationTimes(updatedTimes);
    }
  };

  useEffect(() => {
    fetchReservationTimes();
  }, []);

  return (
    <div className="p-6 min-h-screen">
      <h1 className="text-2xl font-bold mb-6">Restaurant Program Management</h1>

      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4"
          role="alert"
        >
          {error}
        </div>
      )}

      {/* Adăugăm div-ul cu overflow-x-auto pentru a permite scroll orizontal */}
      <div className="overflow-x-auto bg-white shadow-md rounded-lg">
        {/* Setăm o lățime minimă pentru tabel pentru a ne asigura că se poate face scroll */}
        <table className="min-w-[800px] w-full bg-white">
          <thead className="bg-gray-200">
            <tr>
              <th className="py-4 px-6 text-left text-sm font-semibold text-gray-700 whitespace-nowrap">
                Day
              </th>
              <th className="py-4 px-6 text-left text-sm font-semibold text-gray-700 whitespace-nowrap">
                Available
              </th>
              <th className="py-4 px-6 text-left text-sm font-semibold text-gray-700 whitespace-nowrap">
                Opening Time
              </th>
              <th className="py-4 px-6 text-left text-sm font-semibold text-gray-700 whitespace-nowrap">
                Closing Time
              </th>
              <th className="py-4 px-6 text-center text-sm font-semibold text-gray-700 whitespace-nowrap">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {DAYS.map((day, index) => {
              const dayTimes = reservationTimes.find(
                (rt) => rt.day_of_week === index
              );
              const isEditing = editingDay === index;

              return (
                <tr key={day} className="border-b">
                  <td className="p-4 text-gray-700 whitespace-nowrap">{day}</td>
                  <td className="p-4 whitespace-nowrap">
                    <input
                      type="checkbox"
                      checked={dayTimes?.is_available || false}
                      onChange={() => toggleDayAvailability(index)}
                      disabled={isEditing}
                      className="form-checkbox h-5 w-5 text-blue-600 rounded"
                    />
                  </td>
                  {isEditing ? (
                    <>
                      <td className="p-4 whitespace-nowrap">
                        <input
                          type="time"
                          value={dayTimes?.start_time || ""}
                          onChange={(e) =>
                            updateDayTime(index, "start_time", e.target.value)
                          }
                          className="border border-gray-300 rounded-lg px-3 py-2 w-32 focus:border-blue-500 focus:ring focus:ring-blue-500"
                        />
                      </td>
                      <td className="p-4 whitespace-nowrap">
                        <input
                          type="time"
                          value={dayTimes?.end_time || ""}
                          onChange={(e) =>
                            updateDayTime(index, "end_time", e.target.value)
                          }
                          className="border border-gray-300 rounded-lg px-3 py-2 w-32 focus:border-blue-500 focus:ring focus:ring-blue-500"
                        />
                      </td>
                      <td className="p-4 flex justify-center space-x-2 whitespace-nowrap">
                        <button
                          onClick={handleUpdateReservationTimes}
                          className="text-white bg-green-600 hover:bg-green-700 rounded-md px-3 py-2"
                          title="Save Changes"
                        >
                          <Save className="h-5 w-5 text-gray-800" />
                        </button>
                        <button
                          onClick={() => setEditingDay(null)}
                          className="text-white bg-red-600 hover:bg-red-700 rounded-md px-3 py-2"
                          title="Cancel"
                        >
                          <X className="h-5 w-5" />
                        </button>
                      </td>
                    </>
                  ) : (
                    <>
                      <td className="p-4 text-gray-700 whitespace-nowrap">
                        {dayTimes?.start_time || "Not set"}
                      </td>
                      <td className="p-4 text-gray-700 whitespace-nowrap">
                        {dayTimes?.end_time || "Not set"}
                      </td>
                      <td className="p-4 text-center whitespace-nowrap">
                        <button
                          onClick={() => setEditingDay(index)}
                          className="text-white bg-blue-600 hover:bg-blue-700 rounded-md px-3 py-2"
                          title="Edit Reservation Times"
                        >
                          <Edit className="h-5 w-5 text-gray-800" />
                        </button>
                      </td>
                    </>
                  )}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RestaurantProgramManagement;
