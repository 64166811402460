import React, { useState, useEffect } from "react";
import { MapPin, Phone, Mail, Clock, Instagram } from "lucide-react";
import GlassCard from "./GlassCard";

interface Schedule {
  id: number;
  day_of_week: number;
  start_time: string;
  end_time: string;
  is_available: boolean;
  description: string;
}

const ContactComponent: React.FC = () => {
  const [schedule, setSchedule] = useState<Schedule[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const getDayName = (day: number): string => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return days[day];
  };

  useEffect(() => {
    const fetchSchedule = async () => {
      try {
        const response = await fetch("/api/getProgram");
        if (!response.ok) {
          throw new Error("Failed to fetch schedule");
        }
        const data = await response.json();
        setSchedule(data);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An error occurred");
      } finally {
        setLoading(false);
      }
    };

    fetchSchedule();
  }, []);

  const formatTime = (time: string) => {
    try {
      // Handle the case where time might be in different format
      const timeString = time.includes("T") ? time.split("T")[1] : time;
      return new Date(`1970-01-01T${timeString}`).toLocaleTimeString([], {
        hour: "numeric",
        minute: "2-digit",
      });
    } catch (err) {
      console.error("Time formatting error:", err);
      return time; // Return original time string if formatting fails
    }
  };

  return (
    <div className="w-full h-full p-8 max-md:py-1 overflow-y-auto">
      <div className="max-w-md mx-auto grid gap-8 max-md:gap-4 md:grid-cols-1 max-md:text-sm mb-40">
        {/* Contact Information */}
        <GlassCard className="p-6 max-md:p-3 bg-gray-500/5 border-0">
          <h2 className="text-2xl max-md:text-xl font-bold mb-6 max-md:mb-2">
            Contact Information
          </h2>
          <div className="space-y-4 max-md:space-y-2">
            <div className="flex items-center gap-3">
              <MapPin className="text-purple-600" />
              <div>
                <p className="font-semibold">Address:</p>
                <p>Republicii 42</p>
                <p>Tarnaveni</p>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <Phone className="text-purple-600" />
              <div>
                <p className="font-semibold">Phone:</p>
                <p>0775 113 172</p>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <Mail className="text-purple-600" />
              <div>
                <p className="font-semibold">Email:</p>
                <p>noradsociety@gmail.com</p>
              </div>
            </div>
            <div className="flex items-center gap-3">
              <Instagram className="text-purple-600" />
              <div>
                <p className="font-semibold">Instagram:</p>
                <a
                  href="https://www.instagram.com/shishabrothers21/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-purple-600 hover:text-purple-700 transition-colors duration-200"
                >
                  @shishabrothers21
                </a>
              </div>
            </div>
          </div>
        </GlassCard>

        {/* Hours of Operation */}
        <GlassCard className="p-6 max-md:p-3 bg-gray-500/5">
          <h2 className="text-2xl max-md:text-xl font-bold mb-6 flex items-center gap-2">
            <Clock className="text-purple-600" />
            Program
          </h2>

          {loading && <p>Loading schedule...</p>}
          {error && (
            <div className="text-red-500">
              <p>Error loading schedule: {error}</p>
            </div>
          )}

          {!loading && !error && schedule.length === 0 && (
            <p>No schedule data available</p>
          )}

          {!loading && !error && schedule.length > 0 && (
            <div className="space-y-2 max-md:space-y-1">
              {schedule.map((day) => (
                <div
                  key={day.id}
                  className="flex justify-between items-center py-1 max-md:py-0"
                >
                  <div>
                    <span className="font-medium">
                      {getDayName(day.day_of_week)}
                    </span>
                  </div>
                  <span className={!day.is_available ? "text-gray-500" : ""}>
                    {day.is_available
                      ? `${formatTime(day.start_time)} - ${formatTime(
                          day.end_time
                        )}`
                      : "Closed"}
                  </span>
                </div>
              ))}
            </div>
          )}
        </GlassCard>
      </div>
    </div>
  );
};

export default ContactComponent;
